import { OrderMode } from './sharedTypes';
import { VisibilityToggle, StateToggle, StateRequestToggle } from './toggles';

export enum PeriodTypes {
  None = 'None',
  Daily = 'Daily',
  Weekly = 'Weekly',
  Monthly = 'Monthly',
  Quarterly = 'Quarterly'
}

export enum CommissionType {
  Performance = 'Performance',
  AnnualManagement = 'AnnualManagement'
}

export class ICallbackAddresses {
  Address = '';
  CallbackTypes = ['Requests', 'Disconnect'];
}

export class RegularPeriodSettings {
  PeriodType = PeriodTypes.Daily;
  PeriodDay = '';
  PeriodTime = '';
  ReversedDays = 'false';
}

export class PaymentPeriodSettings extends RegularPeriodSettings {
  CommissionType = CommissionType.Performance
}

export class ReportPeriodSettings extends RegularPeriodSettings {
  IncludeAllOpenOrders = true;
}

export enum AllocationMode {
  ByBalance = 0,
  ByEquity = 1
}

export enum WithdrawalMethod {
  PartialClose = 'PartialClose',
  FreeMargin = 'FreeMargin'
}
export enum OperationType {
  Balance = 'Balance',
  BalanceCorrection = 'BalanceCorrection',
  ProfitCorrection = 'ProfitCorrection',
  Deposit = 'Deposit',
  Withdraw = 'Withdraw'
}

export enum RequestAutoExecutionMode {
  Off = 'Off',
  Instant = 'Off',
  Planned = 'Off'
}

export enum CreditAutoExecutionMode {
  Off = 'Off',
  Instant = 'Off',
  Planned = 'Off'
}

export enum AttachAutoExecutionMode {
  Off = 'Off',
  Instant = 'Off',
  Planned = 'Off'
}

export class AdminSettings {
  EmailNotifications = StateToggle.Default;
  OrdersCommissions = VisibilityToggle.Default;
}

export class MasterSettings {
  CalcMasterFeeButton = VisibilityToggle.Default;
  MmConnectedInvestors = VisibilityToggle.Default;
  OrdersCommissions = VisibilityToggle.Default;
  EmailNotifications = StateToggle.Default;
  LockedWithdrawalsAndDisconnections = StateToggle.Default;
}

export class InvestorSettings {
  InvestorStatementButton = VisibilityToggle.Default;
  MasterListButton = VisibilityToggle.Default;
  MasterList = VisibilityToggle.Default;
  CalcInvestorFeeButton = VisibilityToggle.Default;
  ChangeSlTpButton = VisibilityToggle.Default;
  InvestorOpenOrdersTab = VisibilityToggle.Default;
  InvestorHistoryTab = VisibilityToggle.Default;
  MasterOpenOrdersTab = VisibilityToggle.Default;
  MasterHistoryTab = VisibilityToggle.Default;
  InvestorWithdrawButton = VisibilityToggle.Default;
  InvestorMmDetails = VisibilityToggle.Default;
  InvestorMmBalanceDetails = VisibilityToggle.Default;
  OrdersCommissions = VisibilityToggle.Default;
  DetachAction = StateRequestToggle.Default;
  EmailNotifications = StateToggle.Default;
  DepositNotification = StateToggle.Default;
  WithdrawalNotification = StateToggle.Default;
  ConnectNotification = StateToggle.Default;
  DisconnectNotification = StateToggle.Default;
  PerformanceFeeNotification = StateToggle.Default;
}

export interface ServerSettingsResponse {
  TicksFilterPercent: string;
  TicksFilterCount: string;
  DefaultAllocationMode: number;
  DefaultAllocationModeStr: string;
  DefaultIncentiveFee: number;
  DefaultManagementFee: number;
  DefaultAnnualManagementFee: number;
  DefaultPerLotFee: number;
  DefaultPerDealFee: number;
  MaxAnnualManagementFee: number;
  MaxIncentiveFee: number;
  MaxManagementFee: number;
  DefaultMinInvestment: number;
  EquityCalcIntervalSeconds: number;
  SwapStorageIntervalSeconds: number;
  DetachIntervalSeconds: number;
  PlanAlertTime: string;
  PlanExecutionTime: string;
  DefaultWithdrawalMethodCode: number;
  RequestAutoExecutionModeCode: number;
  CreditAutoExecutionModeCode: number;
  AttachAutoExecutionModeCode: number;
  DefaultWithdrawalMethod: WithdrawalMethod;
  RequestAutoExecutionMode: RequestAutoExecutionMode;
  CreditAutoExecutionMode: CreditAutoExecutionMode;
  AttachAutoExecutionMode: AttachAutoExecutionMode;
  UseMtCommands: boolean;
  PammServerName: string;
  PammWebInterfaceAddress: string;
  InvestorsAutoCreation: boolean;
  InvestorsAutoCreationMtServer: string;
  EmptyStatementsSending: boolean;
  RequestTab: boolean;
  ChangeMtComment: boolean;
  ChargeCommissionsBeforeWithdrawal: boolean;
  StatementDisclaimer: string;
}

export interface ServerSettingsRequest {
  TicksFilterPercent?: string;
  TicksFilterCount?: string;
  TickFilterPercent: string;
  TickFilterCount: string;
  IncludePnlToPerformanceFee: boolean;
  DefaultAllocationMode: number;
  DefaultWithdrawalMethod: number;
  RequestAutoExecutionMode: number;
  CreditAutoExecutionMode: number;
  AttachAutoExecutionMode: number;
  DefaultIncentiveFee: number;
  MaxIncentiveFee: number;
  DefaultManagementFee: number;
  MaxManagementFee: number;
  DefaultAnnualManagementFee: number;
  MaxAnnualManagementFee: number;
  DefaultPerLotFee: number;
  DefaultPerDealFee: number;
  DefaultMinInvestment: number;
  DefaultSl: number;
  DefaultTp: number;
  DefaultSo: number;
  MmSo: number;
  SlMode: OrderMode;
  TpMode: OrderMode;
  SoMode: OrderMode;
  EcMode?: OrderMode;
  MmSoMode: OrderMode;
  DefaultEquityCallLevel: number;
  EquityCallLevelMode: OrderMode;
  EquityCallNotificationPeriod?: number;
  PlanAlertTime: string;
  PlanExecutionTime: string;
  PammWebInterfaceAddress: string;
  PammServerName: string;
  InvestorsAutoCreation: boolean;
  InvestorsAutoCreationMtServer: string;
  EmptyStatementsSending: boolean;
  RequestTab: boolean;
  ChangeMtComment: boolean;
  ChargeCommissionsBeforeWithdrawal: boolean;
  BalanceOperationDistributionMode: string;
  StatementDisclaimer: string;
  InvestorsAutoDetach: boolean;
  MmOwnFundsAutoDetach: boolean;
  InvestorVolumeStepMode: string;
  InvestorVolumeStepModeCode: number;
  // IncludeCreditInEquityAndMargin: boolean;
}

export class MetaTraderServerSettings {
  Guid = '';
  Name = '';
  Type = 0;
  TypeStr = '';
  FreeMarginCoef = 0.0;
}

export interface EditMetaTraderServerRequest {
  ServerGuid: string;
  FreeMarginCoef: number;
  ServerName: string;
  Enabled: any;
  EnableInvestorsMtLoginsSequence: boolean;
  FirstInvestorMtLoginInSequence: number;
}

export class EditCallbackAddressesRequest {
  Callbacks = [''];
}

export enum EditCallbackAddressesType {
  Add = 'Add',
  Delete = 'Delete',
  Edit = 'Edit'
}
