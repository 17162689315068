/* eslint-disable no-nested-ternary */
import React from 'react';
import { Button, Dropdown, Navbar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { shadowBox } from '../../styles/styles';
import T4BLogo from '../../logos/logo-dark.png';
import { PAMMState } from '../../redux/reducers/rootReducer';
import fetchRequestsCountAsync from '../../redux/actions/requests/fetchRequestsCount';
import LocalStorageUtils from '../../utils/local-storage';
import { AccountType, User } from '../../types/authTypes';
import InvestorEditModal from '../administrator/investor/modals/InvestorEditModal';
import { Investor } from '../../types/investorTypes';
import { Master } from '../../types/masterTypes';
import MasterEditModal from '../administrator/master/modals/MasterEditModal';
import Icons from '../../icons/icons';
import fetchInvestorDetailsAsync from '../../redux/actions/investor/fetchInvestorDetails';
import fetchMasterDetailsAsync from '../../redux/actions/master/fetchMasterDetails';
import { fetchSharedSettingsAsync } from '../../redux/actions/shared/fetchConfig';
import englishIcon from '../../icons/English.svg';
import deutschIcon from '../../icons/Deutsch.svg';
import frenchIcon from '../../icons/French.svg';
import portugalIcon from '../../icons/Portugal.svg';
import spanishIcon from '../../icons/Spanish.svg';
import russianIcon from '../../icons/Russian.svg';
import arabianIcon from '../../icons/Arabian.svg';
import japanIcon from '../../icons/Japan.svg';
import koreanIcon from '../../icons/Korean.svg';
import georgiaIcon from '../../icons/Georgia.svg';
import chineseIcon from '../../icons/Chinese.svg';



const GenUserNavbar: React.FC<any> = ({
  location,
  signOutFunc,
  currentLang,
  languages,
  onLangChange
}): JSX.Element => {
  const dispatch = useDispatch();
  // PAMM-2341 TODO
  // const showRequestTab = LocalStorageUtils.getValueFromLocalStorage('showRequestTab');
  const showRequestTab: string = useSelector((state: PAMMState) => state.configReducer.requestTab.toString());
  
  const api: string = useSelector((state: PAMMState) => state.configReducer.api);
  const customColors: Record<string, string> = useSelector((state: PAMMState) => state.configReducer.customColorsGeneralUser);
  const customColor = useSelector((state: PAMMState) => state.configReducer.customColor);

  const requestsCount: number = useSelector((state: PAMMState) => state.requestsReducer.count);
  const investor: Investor = useSelector((state: PAMMState) => state.investorReducer.investor);
  const linkedAccounts: Array<Investor> = useSelector((state: PAMMState) => state.investorsReducer.investors);
  const master: Master = useSelector((state: PAMMState) => state.masterReducer.master);
  const userJSON = LocalStorageUtils.getValueFromLocalStorage('user');
  const [user, setUser] = React.useState<User>({} as User);
  const [showEditInvestorModal, setShowEditInvestorModal] = React.useState(false);
  const [showEditMasterModal, setShowEditMasterModal] = React.useState(false);
      
  const linkedId = LocalStorageUtils.getValueFromLocalStorage('linkedIdMobile') || user.Id;

      React.useEffect(() => {
    if (typeof userJSON === 'string') {
      setUser(JSON.parse(userJSON));
    }
  }, [userJSON]);

  // PAMM-2341 TODO
  React.useEffect(() => {
    dispatch(fetchSharedSettingsAsync(api));
  }, [dispatch, api]);

  React.useEffect(() => {
    if (user?.AccountType === AccountType.Master) {
      dispatch(
        fetchMasterDetailsAsync(
          api,
          user?.Id
        )
      );
    }
  }, [dispatch, api, user]);

  React.useEffect(() => {
    dispatch(fetchRequestsCountAsync(api));
  }, [dispatch, api]);

  React.useEffect(() => {

    if (user?.Id && user?.AccountType === AccountType.Investor) {
      let investorId = 0;
      
      if (user?.AccountType === AccountType.Investor) {
        if (user?.AccountInfo?.LinkToken) {
          investorId = Number(linkedId);
        }
        else {
          investorId = user?.Id;
        }
      }

      dispatch(fetchInvestorDetailsAsync(api, investorId));
    }
  }, [dispatch, api, user.Id, linkedId]);

  const btnStyle = {
    background: customColors.light,
    border: 'none',
    color: customColors.additionalText,
    padding: 0,
    marginLeft: '1rem',
    marginRight: '1rem',
    borderRadius: 0,
    fontSize: 16
  } as React.CSSProperties;

  const btnStyleActive = {
    background: customColors.light,
    border: 'none',
    padding: 0,
    marginLeft: '1rem',
    marginRight: '1rem',
    color: customColors.dark,
    borderRadius: 0,
    fontSize: 16
  } as React.CSSProperties;

  const linkStyleActive = {
    borderBottom: `2px solid ${customColors.dark}`
  } as React.CSSProperties;

  const logOutBtn = {
    border: 'none',
    backgroundColor: 'white',
    padding: 0
  } as React.CSSProperties;

  const langDropdownStyle = {
    backgroundColor: 'white',
    border: 'none',
    color: 'gray',
    fontSize: 14
  } as React.CSSProperties;

  const toggleModalVisibility = (): void => {
    if (user?.AccountType === 'investor') {
      setShowEditInvestorModal(!showEditInvestorModal);
    }
    if (user?.AccountType === 'master') {
      setShowEditMasterModal(!showEditMasterModal);
    }
  };

  const getLanguagesFlags = (value: any): string => {
    
    switch (value) {
      case 'English':
        return englishIcon;
      case 'Deutsch':
        return deutschIcon;
      case 'Français':
        return frenchIcon;
      case 'Português':
        return portugalIcon;
      case 'Español':
        return spanishIcon;
      case 'ქართული':
        return georgiaIcon;
      case 'العربية':
        return arabianIcon;
      case '한국어':
        return koreanIcon;
      case 'Русский':
        return russianIcon;
      case '日本語':
        return japanIcon;
      case '中文':
        return chineseIcon;
      default:
        return '';
    }
  };
  
  const languagesItems = Object.keys(languages)
    .filter((lang: string) => lang !== currentLang)
    .map((lang: string, index: number) => {
      return (
        <Dropdown.Item key={`${lang}-${index}`} onClick={() => onLangChange(lang)} className='pt-2 pb-2' style={{paddingLeft: 14}}>
          <img
            src={getLanguagesFlags(languages[lang])}
            className="mr-2"
            height="20px"
            width="20px"
            alt="no flag"
          />
          {languages[lang]}
        </Dropdown.Item>
      );
    });

  return (
    <>
      <Navbar
        style={{ ...shadowBox, height: 66, zIndex: 99 }}
        className="d-flex align-items-stretch"
      >
        <div style={{ maxWidth: 260, marginLeft: '1rem', marginRight: '2rem'}} className='d-flex align-items-center'>
          <Link to="/dashboard">
            <img
              src={T4BLogo}
              alt="Tools for Brokers logo"
            />
          </Link>
        </div>


        <Link
          to="/dashboard"
          className="d-flex align-items-center"
          style={location.pathname === '/dashboard' ? linkStyleActive : {}}
        >
          <Button style={location.pathname === '/dashboard' ? btnStyleActive : btnStyle}>
            <FormattedMessage id="navbar.dashboard" />
          </Button>
        </Link>

        {user?.AccountType === AccountType.Master &&
          user?.AccountOptions?.ShowMmOwnFunds === 'Show' ? (
            <Link
              to="/ownFunds"
              className="d-flex align-items-center"
              style={location.pathname === '/ownFunds' ? linkStyleActive : {}}
            >
              <Button style={location.pathname === '/ownFunds' ? btnStyleActive : btnStyle}>
                <FormattedMessage id="navbar.ownFunds" />
              </Button>
            </Link>
        ) : null}

        {user?.AccountType === AccountType.Investor &&
          investor?.AccountOptions?.MasterList === 'Show' ? (
            <Link
              to="/money_managers"
              className="d-flex align-items-center"
              style={location.pathname.includes('money_managers') ? linkStyleActive : {}}
            >
              <Button style={location.pathname.includes('money_managers') ? btnStyleActive : btnStyle}>
                <FormattedMessage id="navbar.masters" />
              </Button>
            </Link>
        ) : null}
        {(showRequestTab !== 'false') && (
          <Link
            to="/requests"
            className="d-flex align-items-center"
            style={location.pathname === '/requests' ? linkStyleActive : {}}
          >
            <Button style={location.pathname === '/requests' ? btnStyleActive : btnStyle}>
              <FormattedMessage id="navbar.requests" values={{requestsCount}} /> 
            </Button>
          </Link>
        )}

        <div className="d-flex ml-auto mr-3 align-items-center" style={{minWidth: 0}}>
          <Dropdown>
            <Dropdown.Toggle
              id="languages-dropdown"
              className="d-flex align-items-center"
              style={langDropdownStyle}
            >
              <span className="mr-2">{languages[currentLang] || languages.en}</span>
              <span className="pb-1">{Icons.DropdownIcon('gray', '1x')}</span>
            </Dropdown.Toggle>

            <Dropdown.Menu>{languagesItems}</Dropdown.Menu>
          </Dropdown>
          <Button size="sm" variant="link" onClick={toggleModalVisibility} className='ellipsis-style'>
            {user.AccountType === 'investor' ?
            (
              <span className="mr-3" style={{ color: customColor, fontSize: 20 }}>
                {linkedAccounts[0] 
                ? 
                (linkedAccounts[0]?.Name.length > 50 ? `${linkedAccounts[0]?.Name.slice(0, 50)}...` : linkedAccounts[0]?.Name)
                : 
                 (investor?.Name?.length > 50 ? `${investor?.Name.slice(0, 50)}...` : investor?.Name)}
              </span>
            ) : 
            (
              <span className="mr-3" style={{ color: customColor, fontSize: 20 }}>
                { user?.Name?.length > 50 ? `${user?.Name.slice(0, 50)}...` : user?.Name}
              </span>
            )}
          </Button>
          <Button style={logOutBtn} onClick={signOutFunc}>
            <FontAwesomeIcon size="lg" color={customColors.additionalText} icon={faSignOutAlt} />
          </Button>
        </div>
      </Navbar>
      <InvestorEditModal
        investor={linkedAccounts[0] ? linkedAccounts[0] : investor}
        show={showEditInvestorModal}
        toggleVisibility={toggleModalVisibility}
      />
      <MasterEditModal
        master={master}
        show={showEditMasterModal}
        toggleVisibility={toggleModalVisibility}
      />
    </>
  );
};

export default withRouter(GenUserNavbar);
